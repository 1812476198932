import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 3rem 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #E9ECEF;

  &:nth-child(odd){
    background-color: white;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
`;

export const ImgContainer = styled.div`
  border: 3px solid #0c71c3;
  margin-bottom: 25px;
  width: 90%;
  position: relative;

  @media screen and (min-width: 500px) {
    width: 45%;
  }

  @media screen and (min-width: 788px) {
    width: 30%;
  }

  @media screen and (min-width: 980px) {
    width: 22.5%;
  }

  & .plus {
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #003977;
    opacity: 0;
  }

  &:hover .plus {
    opacity: 1;
  }
`;

export const Image = styled(GatsbyImage)`
  z-index: 1;
  aspect-ratio: 1.3;

  &.hov:hover  {
    opacity: 0.15;
    cursor: pointer;
    transition: 0.4s ease-in;
  };
`;

export const ModalContainer = styled.div`
  margin: auto;
  width: fit-content;
  max-width: 80%;
  position: relative;
  z-index: 10;
`;

export const Arrows = styled.div`
  position: absolute;
  color: white;
  cursor: pointer;
  z-index: 10;
  top: 50%;
  font-size: 40px;

  &.left {
    left: 5%;
  }

  &.right {
    right: 5%;
  }
`;

export const ModalBack = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;

  &::before {
    content: "";
  }
`;

export const Modal = styled.div<Dto>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: rgba(0,0,0,0.8);

  @keyframes zoom {
    from {transform: scale(0.1)} 
    to {transform: scale(1)}
  }

  ${ModalContainer} {  
    animation-name: zoom;
    animation-duration: 0.6s;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -50px;
  right: -30px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  &:hover  {
    cursor: pointer;
  };
`;

export const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 50px;
`;

export const PageContainer = styled.div<PageDto>`
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#82C0C7' : 'black')};;
`;

interface Dto {
  isOpen: boolean
}

interface PageDto {
  isActive: boolean
}
