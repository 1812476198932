import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TopBar from '../components/top-bar/top-bar';
import {
  Container, Section, Wrapper, Image, ImgContainer, Modal, ModalContainer, CloseButton, ModalBack, Arrows, Pagination, PageContainer,
} from '../styles/galeria.elements';

interface ImgData extends IGatsbyImageData {
  id: string
  title: string
}

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Galeria"}) {
        title
        images {
          gatsbyImageData
          id
          title
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  const imagesPerPage = 12;
  const pages = Math.ceil(images.length / imagesPerPage);

  const [index, setIndex] = React.useState(0);
  const [imagesPage, setImagesPage] = React.useState(images.slice(0, imagesPerPage));
  const [page, setPage] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = React.useRef<any>(null);

  const onChangeClick = (i: number) => {
    setIndex(i + imagesPerPage * (page - 1));
    setIsOpen(true);
  };

  const onChangeClose = () => {
    setIsOpen(false);
  };

  const onChangeNext = () => {
    if (index === images.length - 1) {
      setIndex(0);
    } else {
      setIndex((i) => i + 1);
    }
  };

  const onChangePrevious = () => {
    if (index === 0) {
      setIndex(images.length - 1);
    } else {
      setIndex((i) => i - 1);
    }
  };

  const onChangePage = (i: number) => {
    if (i !== page) {
      setPage(i);
      setImagesPage(images.slice(imagesPerPage * (i - 1), imagesPerPage * i));
    }
  };

  React.useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Layout>
      <main>
        <TopBar title="GALERIA" imageName="productos.jpg" />
        <Section>
          <Wrapper>
            <Container>
              {
                imagesPage.map((image: ImgData, i: number) => (
                  <ImgContainer key={image.id} onClick={() => onChangeClick(i)}>
                    <Image image={getImage(image) as IGatsbyImageData} alt={image.title} className="hov" />
                    <BsPlusCircleFill className="plus" />
                  </ImgContainer>
                ))
              }
              {
               pages > 1 ? (
                 <Pagination>
                   {
                    Array.from({ length: pages }, (_, i) => i + 1).map((aPage) => (
                      <PageContainer key={aPage} onClick={() => onChangePage(aPage)} isActive={aPage === page}>{aPage}</PageContainer>))
                  }
                 </Pagination>
               ) : null
              }
              <Modal isOpen={isOpen} ref={modalRef} onKeyDown={onChangeClose} tabIndex={0}>
                <ModalBack onClick={onChangeClose} />
                <ModalContainer>
                  <CloseButton onClick={onChangeClose}>&times;</CloseButton>
                  <Image image={getImage(images[index]) as IGatsbyImageData} alt={images[index].title} />
                </ModalContainer>
                <Arrows className="left" onClick={onChangePrevious}><AiOutlineLeft /></Arrows>
                <Arrows className="right" onClick={onChangeNext}><AiOutlineRight /></Arrows>
              </Modal>
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Galeria" />;
